import { Activation } from "modulus-interop/activation-functions";
import { checkRequiredFields } from "modulus-interop/utils";
import { v4 as uuidv4 } from "uuid";
import type { SirenArch as SirenArchType, SirenCreator } from "./types";
import { Parameter, SimulatorComponentSettings } from "modulus-interop/types";

export const defaultSirenArchSettings: Partial<SirenArchType> = {
  input_keys: ["x", "y", "z"],
  parameterized_inputs: {},
  output_keys: ["u", "v", "w", "p"],
  detach_keys: [],
  layer_size: 512,
  nr_layers: 6,
  first_omega: 30.0,
  omega: 30.0,
};

export function SirenArch(
  settings?: SirenArchType,
  variableParameters?: SimulatorComponentSettings["variable_parameters"]
): SirenCreator {
  return {
    id: uuidv4(),
    mode: Object.freeze("Siren"),
    slug: Object.freeze("siren"),
    settings: {
      ...defaultSirenArchSettings,
      ...settings,
    },
    set(settings: Partial<SirenArchType>) {
      Object.assign(this.settings, settings);
      return this.settings;
    },
    validate() {
      checkRequiredFields(["dimension", "input_keys", "decoder_net"], this.settings);
    },
    generateCode() {
      this.validate();
      const { input_keys, output_keys, detach_keys, layer_size, nr_layers, first_omega, omega, normalization } =
        this.settings;

      const parameterizedEquationsKeys = Object.values(variableParameters.equations)?.map(
        (input: Parameter) => `Key("${input?.symbol.toString()}")`
      );
      const parameterizedInputsKeys = Object.values(variableParameters.inputs)?.map(
        (input: Parameter) => `Key("${input?.symbol.toString()}")`
      );
      const allInputs = [...input_keys, ...parameterizedEquationsKeys, ...parameterizedInputsKeys];

      return `
    architecture_${this.slug} = modulus_models["siren"](
        input_keys=[${allInputs?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        output_keys=[${output_keys?.map((key: string) => `Key("${key.toString()}")`).join(", ")}],
        detach_keys=[${detach_keys ? detach_keys.map((key: string) => `"${key.toString()}"`).join(", ") : "[]"}],
        layer_size=${layer_size},
        nr_layers=${nr_layers},
        first_omega=${first_omega},
        omega=${omega},
        normalization=${
          normalization
            ? `{${Object.entries(normalization)
                .map(([key, minmax]) => `"${key.toString()}": (${minmax[0]}, ${minmax[1]})`)
                .join(", ")}}`
            : "None"
        })

    nodes = nodes + [architecture_${this.slug}.make_node(name="architecture_${this.slug}")]`;
    },
  };
}
